import React, { useState, useCallback } from "react";

import { styled } from "@mui/material/styles";

import {
  Typography,
  Modal,
  ButtonBase,
  Box,
  useTheme,
  useMediaQuery,
  Button,
  IconButton,
} from "@mui/material";
import {
  MailOutlineTwoTone,
  DownloadOutlined,
  ArrowBack,
  ArrowForward,
  Close,
} from "@mui/icons-material";

import { GatsbyImage, getImage } from "gatsby-plugin-image";

// pdf viewer
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";

import ApprenticeshipImageBackup from "../../images/careers-images/Apprenticeship.jpeg";
import BookKeeperImageBackup from "../../images/careers-images/Bookkeeper.png";
import CertifiedAccountantImageBackup from "../../images/careers-images/Certified_accountant.png";
import CleanerImageBackup from "../../images/careers-images/cleaner.jpeg";
import EmbeddedSystemEngineerImageBackup from "../../images/careers-images/embedded_system_engineer.png";
import FacilitiesAndSecurityManagerImageBackup from "../../images/careers-images/facilities_and_security_engineer.png";
import HardwareEngineerImageBackup from "../../images/careers-images/hardware_engineer.png";
import ManufacturingFloorManagerImageBackup from "../../images/careers-images/Manufacturing_floor_manager.jpeg";
import MarketingAndCreativeDesignImageBackup from "../../images/careers-images/marketing_and_creative_design.jpeg";
import ReceptionistImageBackup from "../../images/careers-images/receptionist.jpg";
import SoftwareEngineerImageBackup from "../../images/careers-images/software_engineer.jpg";
import WebDeveloperImageBackup from "../../images/careers-images/web_developer.png";

import ApprenticeshipImage from "../../images/careers-images/Apprenticeship.webp";
import BookKeeperImage from "../../images/careers-images/Bookkeeper.webp";
import CertifiedAccountantImage from "../../images/careers-images/Certified_accountant.webp";
import CleanerImage from "../../images/careers-images/cleaner.webp";
import EmbeddedSystemEngineerImage from "../../images/careers-images/embedded_system_engineer.webp";
import FacilitiesAndSecurityManagerImage from "../../images/careers-images/facilities_and_security_engineer.webp";
import HardwareEngineerImage from "../../images/careers-images/hardware_engineer.webp";
import ManufacturingFloorManagerImage from "../../images/careers-images/Manufacturing_floor_manager.webp";
import MarketingAndCreativeDesignImage from "../../images/careers-images/marketing_and_creative_design.webp";
import ReceptionistImage from "../../images/careers-images/receptionist.webp";
import SoftwareEngineerImage from "../../images/careers-images/software_engineer.webp";
import WebDeveloperImage from "../../images/careers-images/web_developer.webp";

// title images
import TitleBanner from "../../images/about-images/title-banner-1.svg";
import UtterBerryLogo from "../../images/utterberry-title-white.svg";

// pdf documents
import CleanerDoc from "../../documents/cleaner.pdf";
import HardwareEngineerDoc from "../../documents/hardware-engineer.pdf";
import ReceptionistDoc from "../../documents/receptionist.pdf";
import SecurityDoc from "../../documents/security.pdf";
import WebStackDeveloperDoc from "../../documents/web-stack-developer.pdf";
import SoftwareEngineerDoc from "../../documents/software-engineer.pdf";

const PREFIX = "CareersLanding";

const classes = {
  root: `${PREFIX}-root`,
  titleContainer: `${PREFIX}-titleContainer`,
  whiteTitle: `${PREFIX}-whiteTitle`,
  landingImage: `${PREFIX}-landingImage`,
  landingStroke: `${PREFIX}-landingStroke`,
  titleText: `${PREFIX}-titleText`,
  buildingImage: `${PREFIX}-buildingImage`,
  workingImage: `${PREFIX}-workingImage`,
  jobsLayout: `${PREFIX}-jobsLayout`,
  jobWrapper: `${PREFIX}-jobWrapper`,
  jobImage: `${PREFIX}-jobImage`,
  jobButton: `${PREFIX}-jobButton`,
  modal: `${PREFIX}-modal`,
  modalCard: `${PREFIX}-modalCard`,
  imageContainer: `${PREFIX}-imageContainer`,
  center: `${PREFIX}-center`,
  pdfButtons: `${PREFIX}-pdfButtons`,
  topBanner: `${PREFIX}-topBanner`,
  landingWrapper: `${PREFIX}-landingWrapper`,
  landingText: `${PREFIX}-landingText`,
  landingTextPoint: `${PREFIX}-landingTextPoint`,
  divider: `${PREFIX}-divider`,
};

const Root = styled("div")(({ theme }) => ({
  width: "100vw",
  background: "white",
  position: "relative",
  overflow: "hidden",

  [`& .${classes.titleContainer}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
  },

  [`& .${classes.whiteTitle}`]: {
    color: "#fff",
    paddingBottom: 5,
    fontWeight: "600",
    fontSize: 53,
    [theme.breakpoints.up("lg")]: {
      fontSize: 68,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 38,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 30,
    },
    whiteSpace: "nowrap",
  },

  [`& .${classes.landingImage}`]: {
    position: "relative",
    zIndex: 1,
    marginLeft: "18px",
    height: "55px",
    paddingBottom: "1px",
    [theme.breakpoints.up("lg")]: {
      height: "68px",
    },
    [theme.breakpoints.down("md")]: {
      height: "40px",
      marginLeft: "14px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "32px",
      marginLeft: "9px",
    },
  },

  [`& .${classes.landingStroke}`]: {
    width: "100%",
    position: "relative",
    top: 0,
    left: 0,
    zIndex: 0,
    objectFit: "fill",
    [theme.breakpoints.down("sm")]: {
      minHeight: "150px",
      width: "600px",
    },
  },

  [`& .${classes.titleText}`]: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    padding: "25px 0px",
    color: "white",
    fontSize: "3em",
    width: "400px",
    fontWeight: "600",
    textAlign: "center",
    position: "absolute",
    left: "50%",
    top: "50%",
    whiteSpace: "nowrap",
    transform: "translate(-50%, -50%)",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.8em",
      padding: "25px 0px",
      margin: "10px 0px",
    },
    "@media(max-width: 500px)": {
      position: "relative",
      transform: "translate(-50%, 0%)",
      left: "50%",
      top: "0%",
    },
  },

  [`& .${classes.buildingImage}`]: {
    width: 65,
    [theme.breakpoints.down("sm")]: {
      width: 45,
    },
    "@media(max-width: 500px)": {
      display: "none",
    },
  },

  [`& .${classes.workingImage}`]: {
    width: 65,
    [theme.breakpoints.down("sm")]: {
      width: 45,
    },
    "@media(max-width: 500px)": {
      display: "none",
    },
  },

  [`& .${classes.jobsLayout}`]: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    padding: "0px 30px 30px",
    width: "100%",
    gap: 8,
    maxWidth: "1200px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr 1fr",
      padding: "0px 10px 10px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      padding: "0px 20px 20px",
    },
  },

  [`& .${classes.jobWrapper}`]: {
    width: "100%",
    border: "2px solid #540096",
    borderRadius: "4px",
    overflow: "hidden",
    minHeight: "150px",
    position: "relative",
  },

  [`& .${classes.jobImage}`]: {
    width: "100%",
  },

  [`& .${classes.jobButton}`]: {
    "&:hover": {
      transform: "scale(1.02)",
    },
  },

  [`& .${classes.modal}`]: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "column",
    overflow: "scroll",
    height: "100%",
    padding: "20px",
  },

  [`& .${classes.modalCard}`]: {
    background: "white",
    borderRadius: "4px",
    padding: "15px",
    boxShadow: "0 30px 40px 0 rgb(16 36 94 / 20%)",
  },

  [`& .${classes.imageContainer}`]: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  [`& .${classes.center}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "100%",
  },

  [`& .${classes.pdfButtons}`]: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row",
    alignItems: "center",
    padding: "6px",
    width: "100%",
  },

  [`& .${classes.topBanner}`]: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px 10px",
  },

  [`& .${classes.landingWrapper}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    flexDirection: "column",
    position: "relative",
    margin: "0px 0px 30px 0px",
  },

  [`& .${classes.landingText}`]: {
    maxWidth: 820,
    zIndex: 2,
    margin: "8px 0px",
    textAlign: "center",
    fontSize: "1.1rem",
    padding: "0px 20px",
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "0.9rem",
      margin: "0px 0px 20px 0px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
      margin: "0px 0px 20px 0px",
    },
  },

  [`& .${classes.divider}`]: {
    width: "35%",
    borderBottom: "1px solid silver",
    margin: "10px 0px",
  },

  [`& .${classes.landingTextPoint}`]: {
    maxWidth: 820,
    zIndex: 2,
    // margin: "2px 0px",
    textAlign: "center",
    fontSize: "1.1rem",
    padding: "0px 15px",
    fontWeight: "bold",
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "0.9rem",
      // margin: "0px 0px 3px 0px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
      // margin: "0px 0px 3px 0px",
    },
  },
}));

const ModalCard = styled(Box)(({ theme }) => ({
  background: "white",
  borderRadius: "4px",
  padding: "15px",
  boxShadow: "0 30px 40px 0 rgb(16 36 94 / 20%)",

  [`& .${classes.topBanner}`]: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px 10px",
  },

  [`& .${classes.pdfButtons}`]: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row",
    alignItems: "center",
    padding: "6px",
    width: "100%",
  },
}));

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const jobs = [
  {
    title: "Apprenticeship",
    image: ApprenticeshipImage,
    backup: ApprenticeshipImageBackup,
  },
  {
    title: "Book Keeper",
    image: BookKeeperImage,
    backup: BookKeeperImageBackup,
  },
  {
    title: "Manufacturing Floor Manager",
    image: ManufacturingFloorManagerImage,
    backup: ManufacturingFloorManagerImageBackup,
  },
  {
    title: "Hardware Engineer",
    image: HardwareEngineerImage,
    document: HardwareEngineerDoc,
    backup: HardwareEngineerImageBackup,
  },
  {
    title: "Embedded System Engineer",
    image: EmbeddedSystemEngineerImage,
    backup: EmbeddedSystemEngineerImageBackup,
  },
  {
    title: "Software Engineer",
    image: SoftwareEngineerImage,
    backup: SoftwareEngineerImageBackup,
    document: SoftwareEngineerDoc,
  },
  {
    title: "Web Stack Developer",
    image: WebDeveloperImage,
    document: WebStackDeveloperDoc,
    backup: WebDeveloperImageBackup,
  },
  {
    title: "Certified Accountant",
    image: CertifiedAccountantImage,
    backup: CertifiedAccountantImageBackup,
  },
  {
    title: "Marketing and Creative Design",
    image: MarketingAndCreativeDesignImage,
    backup: MarketingAndCreativeDesignImageBackup,
  },
  {
    title: "Receptionist",
    image: ReceptionistImage,
    document: ReceptionistDoc,
    backup: ReceptionistImageBackup,
  },

  {
    title: "Facilities and Security Engineer",
    image: FacilitiesAndSecurityManagerImage,
    document: SecurityDoc,
    backup: FacilitiesAndSecurityManagerImageBackup,
  },
  {
    title: "Cleaner",
    image: CleanerImage,
    document: CleanerDoc,
    backup: CleanerImageBackup,
  },
];

const JobButton = ({ title, image, document, backup }) => {
  const [openDoc, setOpenDoc] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const handleClose = useCallback(() => setOpenDoc(false), []);

  const onDocumentLoadSuccess = useCallback(({ numPages }) => {
    setLoaded(true);
    setNumPages(numPages);
  }, []);

  const gImage = getImage(image);

  // media breaks for PDF size
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));
  const medium = useMediaQuery(theme.breakpoints.between("sm", "md"));

  return (
    <div className={classes.jobWrapper}>
      <ButtonBase
        className={classes.jobButton}
        onClick={() => setOpenDoc(true)}
      >
        <div>
          <Typography
            variant={"subtitle1"}
            style={{
              fontWeight: "500",
            }}
          >
            {title}
          </Typography>
          <GatsbyImage
            image={gImage}
            className={classes.jobImage}
            objectFit={"contain"}
            alt={`${title} - Image`}
          />
          {/* <img
            src={image}
            className={classes.jobImage}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = backup;
            }}
          /> */}
        </div>
      </ButtonBase>
      {!document && (
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            zIndex: 1,
          }}
          className={"backdrop-blur-careers"}
        ></div>
      )}
      {document && (
        <Modal
          open={openDoc}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
            overflow: "scroll",
            height: "100%",
            padding: "20px",
          }}
        >
          <ModalCard>
            <Box className={classes.topBanner}>
              <IconButton
                color='primary'
                component='span'
                onClick={handleClose}
              >
                <Close />
              </IconButton>
              <Box
                className={classes.pdfButtons}
                style={{
                  display: loaded ? "" : "none",
                }}
              >
                <Button
                  startIcon={<DownloadOutlined />}
                  variant='contained'
                  style={{
                    marginRight: "10px",
                  }}
                  href={document}
                  target={"_blank"}
                >
                  Download
                </Button>
                <Button
                  startIcon={<MailOutlineTwoTone color={"#540096"} />}
                  variant='contained'
                  style={{
                    marginRight: "10px",
                  }}
                  href={"mailto:careers@utterberry.com"}
                >
                  Email
                </Button>
                <IconButton
                  color='primary'
                  component='span'
                  onClick={() => {
                    if (pageNumber > 1) {
                      setPageNumber(pageNumber - 1);
                    }
                  }}
                >
                  <ArrowBack />
                </IconButton>
                <span>
                  {pageNumber} / {numPages}
                </span>
                <IconButton
                  color='primary'
                  component='span'
                  onClick={() => {
                    if (pageNumber < numPages) {
                      setPageNumber(pageNumber + 1);
                    }
                  }}
                >
                  <ArrowForward />
                </IconButton>
              </Box>
            </Box>
            <Document file={document} onLoadSuccess={onDocumentLoadSuccess}>
              <Page
                pageNumber={pageNumber}
                scale={small ? 0.48 : medium ? 0.65 : 1}
              />
            </Document>
          </ModalCard>
        </Modal>
      )}
    </div>
  );
};

const CareersLanding = (props) => {
  return (
    <Root>
      <div
        style={{
          width: "100%",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <img
          src={TitleBanner}
          className={classes.landingStroke}
          alt={"Hexagon Banner"}
        />
        <div
          style={{
            zIndex: 2,
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -70%)",
          }}
        >
          <span className={classes.titleContainer}>
            <Typography variant={"h2"} className={classes.whiteTitle}>
              Careers at
            </Typography>
            <img
              src={UtterBerryLogo}
              className={classes.landingImage}
              alt={"UtterBerry"}
            />
          </span>
        </div>
      </div>
      <div className={classes.landingWrapper}>
        <Typography className={classes.landingText}>
          {`UtterBerry is currently hiring in a range of roles for both our
          technical and administrative teams. A majority of the roles listed
          below are for our Leeds office as we are looking to rapidly expand
          within our manufacturing and innovation hub.`}
        </Typography>
        <div className={classes.divider}></div>
        <Typography variant={"h4"}>How to Apply</Typography>
        <Typography className={classes.landingText}>
          This page displays the roles that we are currently hiring for, along
          with their job specifications. If you wish to reach out to us directly
          about an opportunity at UtterBerry please read the respective job
          specification and email{" "}
          <a
            href={"mailto:careers@utterberry.com"}
            style={{ color: "#540096", fontWeight: "bold" }}
          >
            careers@utterberry.com
          </a>{" "}
          with your:
        </Typography>
        <Typography className={classes.landingTextPoint}>
          Cover Letter
        </Typography>
        <Typography className={classes.landingTextPoint}>
          Curriculum Vitae
        </Typography>
        <Typography className={classes.landingTextPoint}>
          Eligibility to work in the UK
        </Typography>
        <Typography className={classes.landingText}>
          You can find more information by getting in touch with us on the
          Contact page.
        </Typography>
      </div>
      <div className={classes.center}>
        <div className={classes.jobsLayout}>
          {/* <JobButton
            title={jobs[0].title}
            image={props.images.apprenticeship}
            document={jobs[0].document}
          /> */}
          {/* <JobButton
            title={jobs[1].title}
            image={props.images.bookKeeper}
            document={jobs[1].document}
          /> */}
          {/* <JobButton
            title={jobs[2].title}
            image={props.images.manufacturingFloorManager}
            document={jobs[2].document}
          /> */}
          <JobButton
            title={jobs[3].title}
            image={props.images.hardwareEngineer}
            document={jobs[3].document}
          />
          {/* <JobButton
            title={jobs[4].title}
            image={props.images.embeddedSystemEngineer}
            document={jobs[4].document}
          /> */}
          <JobButton
            title={jobs[5].title}
            image={props.images.softwareEngineer}
            document={jobs[5].document}
          />
          <JobButton
            title={jobs[6].title}
            image={props.images.webDeveloper}
            document={jobs[6].document}
          />
          {/* <JobButton
            title={jobs[7].title}
            image={props.images.certifiedAccountant}
            document={jobs[7].document}
          /> */}
          {/* <JobButton
            title={jobs[8].title}
            image={props.images.marketingAndCreativeDesign}
            document={jobs[8].document}
          /> */}
          <JobButton
            title={jobs[9].title}
            image={props.images.receptionist}
            document={jobs[9].document}
          />
          <JobButton
            title={jobs[10].title}
            image={props.images.facilitiesAndSecurityManager}
            document={jobs[10].document}
          />
          <JobButton
            title={jobs[11].title}
            image={props.images.cleaner}
            document={jobs[11].document}
          />
        </div>
      </div>
    </Root>
  );
};

export default CareersLanding;
