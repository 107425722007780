import * as React from "react";

import { graphql } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";

// sections
import CareersLanding from "../components/careers-page";

const Root = styled.main`
  padding: 0px;
  width: 100vw;
  background: white;
  overflow: hidden;
  margin-top: 65px;

  @media (max-width: 600px) {
    margin-top: 57px;
  }

  @media (max-height: 500px) {
    margin-top: 49px;
  }
`;

// markup
const CareersPage = (props) => {
  return (
    <Root>
      <CareersLanding images={props.data} />
    </Root>
  );
};

export function Head() {
  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
    >
      <title>Careers - UtterBerry</title>
      <meta
        name='description'
        content='Careers - UtterBerry is a provider of Artificially Intelligent wireless sensor systems for the development of Smart Cities and the revolutionisation of sensing capabilities.'
      ></meta>
    </Helmet>
  );
}

export const pageQuery = graphql`
  query {
    apprenticeship: file(
      relativePath: { eq: "careers-images/Apprenticeship.webp" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 380, height: 230)
      }
    }
    bookKeeper: file(relativePath: { eq: "careers-images/Bookkeeper.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 380, height: 230)
      }
    }
    certifiedAccountant: file(
      relativePath: { eq: "careers-images/Certified_accountant.webp" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 380, height: 230)
      }
    }
    cleaner: file(relativePath: { eq: "careers-images/cleaner.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 380, height: 230)
      }
    }
    embeddedSystemEngineer: file(
      relativePath: { eq: "careers-images/embedded_system_engineer.webp" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 380, height: 230)
      }
    }
    facilitiesAndSecurityManager: file(
      relativePath: {
        eq: "careers-images/facilities_and_security_engineer.webp"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 380, height: 230)
      }
    }
    hardwareEngineer: file(
      relativePath: { eq: "careers-images/hardware_engineer.webp" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 380, height: 230)
      }
    }
    manufacturingFloorManager: file(
      relativePath: { eq: "careers-images/Manufacturing_floor_manager.webp" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 380, height: 230)
      }
    }
    marketingAndCreativeDesign: file(
      relativePath: { eq: "careers-images/marketing_and_creative_design.webp" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 380, height: 230)
      }
    }
    receptionist: file(
      relativePath: { eq: "careers-images/receptionist.webp" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 380, height: 230)
      }
    }
    softwareEngineer: file(
      relativePath: { eq: "careers-images/software_engineer.webp" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 380, height: 230)
      }
    }
    webDeveloper: file(
      relativePath: { eq: "careers-images/web_developer.webp" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 380, height: 230)
      }
    }
  }
`;

export default CareersPage;
